
import React from 'react';
import { View, Text, TouchableOpacity, ActivityIndicator, Image, TextInput, StyleSheet, Dimensions, Animated, FlatList } from 'react-native';
import { Feather } from "@expo/vector-icons" 
import Colors from '../utils/Colors';
import FontStyles from '../utils/FontStyles';
import API from '../utils/API';
import { LinearGradient } from 'expo-linear-gradient';
import SocketIOClient from 'socket.io-client';
import {  isMobile } from "react-device-detect";

const SCREEN_WIDTH = Dimensions.get("screen").width
const SCREEN_HEIGHT = Dimensions.get("screen").height

export default class MainScreen extends React.PureComponent {
  constructor(props){
    super(props);

    this.socket = null
    this.imageValue = new Animated.Value(1)
    this.transformImage = {
      transform: [
        {
          translateX: this.imageValue.interpolate({
            inputRange: [0, 1],
            outputRange: [40, 0]
          })
        }
      ]
    }

    this.pnr = ""
    this.fullname = ""
    this.email = ""

    this.state = {
      loading: true,
      updateUserText: "Son bir adım kaldı, bilgileriniz girerek kaydınızı tamamlayın!",
      selectedEvents: {},
      events: [],
      updateLoading: false,
      errorAccured: false,
      selectedEventImage: "",
      watchButtonFocused: false,
      pnrScreenVisible: false,
      new_user: false,
      pnrText: "Etkinliğe giriş yapmak için lütfen PNR kodunuzu giriniz."
    }
  }

  componentDidMount() {
    this.getLiveEvents()
    this.initUser()
  }

  initUser = () => {
    API.initUser().then((response) => {
      if(response && response.data && response.data.config){
        if(response.data.info && !response.data.info.new_user){
          let socketUrl = response.data.config.socket
          this.socketUrl = socketUrl
          this.socket = new SocketIOClient(socketUrl, {transports: ["websocket"]})
        }else{
          this.setState({new_user: response.data.info.new_user})
        }
      }
    }).catch((e) => {
      setTimeout(() => {
        this.initUser()
      }, 5000);
    })
  }

  changeImage = () => {
    let currentImage = this.state.selectedEvents.image

    Animated.spring(this.imageValue, {
      toValue: 0,
      useNativeDriver: true
    }).start(() => {
      this.setState({selectedEventImage: currentImage})
      Animated.timing(this.imageValue, {
        toValue: 1,
        useNativeDriver: true,
        timing: 200,
      }).start()
    })
  }

  updateUser = () => {
    if(this.fullname.length < 1 || this.email.length < 1 ){
      this.setState({updateUserText: "Lütfen bilgilerinizi eksiksiz giriniz."})
      return null
    }

    this.setState({updateLoading: true}, () => {
      API.updateUser(this.fullname, this.email).then((response) => {
        if(response.success){
          if(response.data.user && response.data.user && response.data.user.config){
            let socketUrl = response.data.user.config.socket
            this.socketUrl = socketUrl
            this.socket = new SocketIOClient(socketUrl, {transports: ["websocket"]})
            this.setState({updateLoading: false, new_user: false})
          }else{
            this.setState({updateLoading: false, updateUserText: "İnternet bağlantınızı kontrol ediniz."}) 
          }
        }else{
          this.setState({updateLoading: false, updateUserText: response.message})
        }
      }).catch((e) => {
        console.log(e)
        this.setState({updateLoading: false, updateUserText: "İnternet bağlantınızı kontrol ediniz."})
      })
    })
  }


  sendPnr = () => {
    this.setState({pnrLoading: true}, () => {
      API.sendPNR(this.pnr).then((response) => {
        if(response.success == false){
          this.setState({pnrLoading: false, pnrText: response.message})
        }else{
          this.getLiveEvents()
        } 
      }).catch((e) => {
        this.setState({pnrLoading: false, pnrText: "Bağlantı hatası. Lütfen tekrar deneyiniz."})
      })
    })
  }

  getEvents = () => {
    API.getEvents().then((response) => {
      let data = response.data
      let finalData = this.state.events
      let eventsArr = []
      if(data && data.length > 0){
        for (let i = 0; i < data.length; i++) {
          if(data[i].type == "5"){
            eventsArr = data[i].events
          }
        }
      }
      finalData = finalData.concat(eventsArr)
      this.pnr = ""
      this.setState({loading: false, events: finalData, pnrLoading: false, pnrScreenVisible: false})
    }).catch((e) => {
      this.setState({errorAccured: true, loading: false, pnrLoading: false, pnrScreenVisible: false})
    })
  }

  getLiveEvents = () => {
    this.setState({loading: true}, () => {
      API.getLiveEvents().then((response) => {
        let data = []
        let selectedEvent = {}
        if(response.data && response.data[0] && response.data[0].events && response.data[0].events.length > 0){
          data = response.data[0].events
          selectedEvent = response.data[0].events[0]
        }
        this.setState({selectedEventImage: selectedEvent.image, selectedEvents: selectedEvent, events: data}, () => {
          this.getEvents()
        })
      }).catch((e) => {
        this.setState({errorAccured: true, loading: false, pnrLoading: false, pnrScreenVisible: false})
      })
    })
  }

  renderItem = ({item, index}) => {
    return (
      <TouchableOpacity onFocus={() => this.setState({selectedEvents: item}, () => this.changeImage())} style={[this.state.selectedEvents.id == item.id ? styles.selectedButton : styles.eventButton]} activeOpacity={0.9}>
        <Image source={{uri: item.image}} blurRadius={3} style={styles.cardImage}/>
        <Text style={[FontStyles.body, {zIndex: 100, marginRight: 20, fontWeight: "700", marginLeft: 20, marginTop: 15, color: Colors.white.alpha1}]}>
          {item.title}
        </Text>
        <Text style={[FontStyles.footnote, {zIndex: 100, fontWeight: "600", marginLeft: 20, marginTop: 10, color: Colors.white.alpha1}]}>
          {item.subtitle3}
        </Text>
        <Text style={[FontStyles.footnote, {zIndex: 100, fontWeight: "600", marginLeft: 20, marginTop: 10, color: Colors.white.alpha1}]}>
          {item.subtitle}
        </Text>
      </TouchableOpacity>
    )
  }

  onPressEventButton = () => {
    if(this.state.selectedEvents.target && this.state.selectedEvents.target === "live_event" && this.state.selectedEvents.difference){
      //pnr ok, etkinlik aktif
      this.props.navigation.navigate("LiveStream", {socket: this.socket, eventId: this.state.selectedEvents.id, reloadHome: this.getLiveEvents})
    }else if(this.state.selectedEvents.target && this.state.selectedEvents.target === "event_detail" && this.state.selectedEvents.difference){
      //pnr ok değil, etkinlik var.
      this.setState({pnrScreenVisible: true})
    }else{
      //live event değil 
    }
  }

  logout = () => {
    API.logout().then(() => {
      this.props.navigation.navigate("RegisterStack")
    }).catch(() => {
      this.props.navigation.navigate("RegisterStack")
    })
  }
  
  render() {
    return ( 
      <View style={styles.container}>
        {
          this.state.loading ? 
          <View>
            <ActivityIndicator size={"large"} color={"white"}/>
            <Text style={[FontStyles.title2, {marginTop: 25, color: Colors.white.alpha1}]}>
              Yükleniyor...
            </Text>
          </View>
          :
          this.state.errorAccured ? 
          <View>
            <Text style={[FontStyles.title2, {color: Colors.white.alpha1, marginBottom: 20}]}>
              Bağlantı kurulamadı.
            </Text>
            <TouchableOpacity style={styles.reloadButton} onPress={this.getLiveEvents} activeOpacity={0.9}>
              <Text style={[FontStyles.body, {textAlign: "center", marginVertical: 5, color: Colors.white.alpha1}]}>
                Yeniden Dene
              </Text>
            </TouchableOpacity> 
          </View>
          :
          <View style={styles.container}>
            <View style={{flex: 7, flexDirection: "row", width: "100%"}}>
              <View style={{flex:  isMobile ? 9 : 4, height: "100%", justifyContent: "center"}}>
                <Text style={[isMobile ? FontStyles.title3 : FontStyles.title1, {marginLeft: "10%", marginRight: 40, minWidth: 200, marginTop: 40, fontWeight: "600", marginBottom: 10, color: Colors.white.alpha1}]}>
                  {this.state.selectedEvents.title}
                </Text>
                <Text style={[FontStyles.footnote, {marginLeft: "10%", marginRight: 40, minWidth: 150,  marginTop: 5, fontWeight: "500", marginBottom: 5, color: Colors.white.alpha07}]}>
                  <Feather name={"calendar"} size={isMobile ? 17 : 24} color={Colors.white.alpha07}/>
                  { "   " + this.state.selectedEvents.subtitle2}
                </Text>
                <Text style={[FontStyles.footnote, {marginLeft: "10%", marginRight: 40, minWidth: 150,  marginTop: 5, fontWeight: "400", marginBottom: 20, color: Colors.white.alpha07}]}>
                  <Feather name={"clock"} size={isMobile ? 17 : 24} color={Colors.white.alpha07}/>
                  {"   " + this.state.selectedEvents.subtitle3}
                </Text>
                <TouchableOpacity  
                  // onFocus={() => this.setState({watchButtonFocused: true})} 
                  // onBlur={() => this.setState({watchButtonFocused: false})} test
                  activeOpacity={0.9}
                  onPress={this.onPressEventButton} 
                  style={[styles.reloadButton, {borderWidth: 3, borderColor: this.state.watchButtonFocused ? "white" : "transparent", backgroundColor: (this.state.selectedEvents && this.state.selectedEvents.difference) ? Colors.red.alpha1 : Colors.gray.alpha1, alignSelf: "baseline", marginLeft: "10%"}]}>
                  <Text style={[FontStyles.subhead, {fontWeight: "500", color: Colors.white.alpha1}]}>
                    {(this.state.selectedEvents && this.state.selectedEvents.difference) ? "Şimdi İzle" : "Yaklaşan Etkinlik"}
                  </Text>
                </TouchableOpacity>
                {
                  !(this.state.selectedEvents && this.state.selectedEvents.difference) &&
                  <Text style={[FontStyles.footnote, {marginLeft: "10%", marginRight: 40,  marginTop: 20, fontWeight: "400", marginBottom: 20, color: Colors.white.alpha07}]}>
                    <Feather name={"map-pin"} size={18} color={Colors.white.alpha07}/>
                    {"   Biletler SOSYO mobil uygulamasında!"}
                  </Text>
                }
              </View> 
              <View style={{flex: 6, height: "100%"}}>
                <Animated.Image resizeMode={"cover"} resizeMethod={"resize"} source={{uri: this.state.selectedEventImage}} style={[styles.image, this.transformImage, {opacity: this.imageValue}]}/>
                <LinearGradient style={styles.graBottom} colors={["transparent", Colors.black.alpha1]}/>
              </View>
              <LinearGradient start={[0,1]} end={[1,1]} style={[styles.graLeft, {left: isMobile ? "60%" : "40%", width:  isMobile ? "20%" : "20%"}]} colors={[Colors.black.alpha1, "transparent"]}/>
            </View>

            <View style={{flex: 4, width: "100%", backgroundColor: Colors.black.alpha1, paddingBottom: 30}}>
              <Text style={[FontStyles.title3, {marginLeft: 40, fontWeight: "600", marginBottom: 20, color: Colors.white.alpha1}]}>
                Canlı Yayınlar
              </Text>
              <FlatList
                data={this.state.events}
                horizontal
                showsHorizontalScrollIndicator={false}
                keyExtractor={(item, index) => item.id + index + "a"}
                renderItem={this.renderItem}
                style={{width: "100%", flex: 1, backgroundColor: Colors.black.alpha1}}
                contentContainerStyle={{paddingHorizontal: 40}}
              />
            </View>

            <View style={{flexDirection: "row", alignItems: "center", position: "absolute", top: 20, right: 20}}>
              <TouchableOpacity style={[styles.logout, {backgroundColor: Colors.red.alpha1}]} onPress={() => this.setState({pnrScreenVisible: true})}>
                <Text style={[FontStyles.caption1, {fontWeight: "500", textAlign: "center", color: Colors.white.alpha1}]}>
                  PNR Gir
                </Text>
              </TouchableOpacity>
              <TouchableOpacity style={[styles.logout]} onPress={this.getLiveEvents}>
                <Feather name={"rotate-ccw"} color={Colors.white.alpha08} size={16}/>
              </TouchableOpacity>
              <TouchableOpacity style={styles.logout} onPress={this.logout}>
                <Text style={[FontStyles.caption1, {fontWeight: "500", textAlign: "center", color: Colors.white.alpha1}]}>
                  Çıkış Yap
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        }

        {
          this.state.pnrScreenVisible &&
          <View style={{position: "absolute", zIndex: 10000, justifyContent: "center", alignItems:"center", backgroundColor: Colors.black.alpha08, top:0, bottom:0, right: 0, left: 0 }}>
            <View style={{padding: 25, alignSelf: "center", minWidth: "40%", marginHorizontal: isMobile ? 20 : 0, backgroundColor: "rgba(26,25,25,1.0)", borderRadius: 10}}>
              <Text style={[FontStyles.title2, { color: Colors.white.alpha1,  fontWeight: "500", textAlign: "center", marginBottom: 20}]}>
                PNR Kodu
              </Text>
              <Text style={[FontStyles.body, { color: Colors.white.alpha08, textAlign: "center"}]}>
                {this.state.pnrText}
              </Text>
              <TextInput
                placeholder={"PNR Kodu"}
                style={[FontStyles.body, styles.textInput]}
                autoCapitalize={"characters"}
                onChangeText={text => this.pnr = text}
                maxLength={8}
                onSubmitEditing={this.sendPnr}
              />
              
              <View style={{flexDirection: "row", alignItems: 'center', marginTop: 15,}}>
              
              <TouchableOpacity style={[styles.buttonSend, {marginRight: 7.5, backgroundColor: Colors.white.alpha03}]} activeOpacity={0.9} onPress={() => this.setState({pnrScreenVisible: false})}>
                <Text style={[FontStyles.body, {color:Colors.white.alpha1, fontWeight: "500"}]}>
                  Vazgeç
                </Text>
              </TouchableOpacity>
              <TouchableOpacity disabled={this.state.pnrLoading} style={[styles.buttonSend, {marginLeft: 7.5}]} activeOpacity={0.9} onPress={this.sendPnr}>
                {
                  this.state.pnrLoading ? 
                  <ActivityIndicator color={Colors.white.alpha1}/>
                  :
                  <Text style={[FontStyles.body, {color:Colors.white.alpha1, fontWeight: "500"}]}>
                    Gönder
                  </Text>
                }
              </TouchableOpacity>
              </View>
              
              <Text style={[FontStyles.caption1, { color: Colors.white.alpha05, textAlign: "center", marginTop: 15}]}>
                {"PNR kodunuz yoksa SOSYO uygulamasını indirip bilet satın alabilirsiniz."}
              </Text>
            </View>
          </View>
        }

        {
          this.state.new_user &&
          <View style={{position: "absolute", zIndex: 10000, justifyContent: "center", alignItems:"center", backgroundColor: Colors.black.alpha08, top:0, bottom:0, right: 0, left: 0 }}>
            <View style={{padding: 25, alignSelf: "center", marginHorizontal: isMobile ? 20 : 0,  minWidth: "40%", backgroundColor: "rgba(26,25,25,1.0)", borderRadius: 10}}>
              <Text style={[FontStyles.title2, { color: Colors.white.alpha1, fontWeight: "500", textAlign: "center", marginBottom: 20}]}>
                Kullanıcı Bilgileri
              </Text>
              <Text style={[FontStyles.body, { color: Colors.white.alpha08, textAlign: "center"}]}>
                {this.state.updateUserText}
              </Text>
              <TextInput
                placeholder={"Ad Soyad"}
                style={[FontStyles.body, styles.textInput]}
                autoCapitalize={"characters"}
                onChangeText={text => this.fullname = text}
                onSubmitEditing={() => this.t2.focus()}
              />
              <TextInput
                ref={ref =>this.t2 = ref}
                placeholder={"E-Posta Adresiniz"}
                style={[FontStyles.body, styles.textInput]}
                autoCapitalize={"characters"}
                onChangeText={text => this.email = text}
                onSubmitEditing={() => this.updateUser()}
              />

              
              <View style={{flexDirection: "row", alignItems: 'center', marginTop: 20,}}>
                <TouchableOpacity disabled={this.state.updateLoading} style={[styles.buttonSend]} activeOpacity={0.9} onPress={this.updateUser}>
                  {
                    this.state.updateLoading ? 
                    <ActivityIndicator color={Colors.white.alpha1}/>
                    :
                    <Text style={[FontStyles.body, {color:Colors.white.alpha1, fontWeight: "500"}]}>
                      Kaydet
                    </Text>
                  }
                </TouchableOpacity>
              </View>
            </View>
          </View>
        }

      </View>
    )
    
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.black.alpha1
  },

  textInput: {
    width: "100%",
    paddingVertical: 17,
    backgroundColor: Colors.white.alpha03,
    borderRadius: 5, 
    textAlign: "center",
    marginTop: 25,
    color: Colors.white.alpha1,
    fontWeight: "700"
  },

  buttonSend: {
    flex: 1,
    paddingVertical: 17,
    borderRadius: 5, 
    backgroundColor: Colors.red.alpha1,
    textAlign: "center",
    justifyContent: 'center',
    alignItems: "center",
    
  },


  reloadButton: {
    marginTop: 10,
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: "center",
    backgroundColor: Colors.red.alpha1,
  },
  cardImage: {
    position: "absolute",
    top: 0,
    opacity: 0.66,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -5
  },
  logout: {
    paddingVertical: 10, 
    paddingHorizontal: 20,
    borderRadius: 5,
    marginRight: 10,
    backgroundColor: Colors.white.alpha02
  },
  logout2: {
    position: "absolute",
    top: 20, 
    right: 20,
    paddingVertical: 7.5, 
    paddingHorizontal: 20,
    borderRadius: 5,
    backgroundColor: Colors.white.alpha02
  },

  eventButton: {
    width: SCREEN_WIDTH * 0.35,
    minWidth: 200,
    minHeight: 80,
    height: "100%",
    overflow: "hidden",
    backgroundColor: Colors.grayLight.alpha02,
    borderRadius: 5,
    marginRight: 20
  },
  selectedButton: {
    width: SCREEN_WIDTH * 0.35,
    minWidth: 200,
    height: "100%",
    overflow: "hidden",
    borderWidth: 3,
    borderColor: Colors.white.alpha1,
    backgroundColor: Colors.grayLight.alpha02,
    borderRadius: 5,
    marginRight: 20
  },
  graLeft: {
    position: "absolute",
    top: 0,
    left:"40%",
    bottom: 0,
    width: "20%"
  },
  graBottom: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "15%"
  },
  image: {
    position: "absolute",
    top: 0,
    right: 0,
    height: SCREEN_HEIGHT,
    width: "100%",
  }
  
})