import { StyleSheet, Dimensions } from 'react-native';
import Colors from './Colors';
const SCREEN_WIDTH = Dimensions.get("window").width;
const WIDE_DEVICE = SCREEN_WIDTH > 400

const fontWeight = "400"
const color = Colors.dark.alpha1

export default StyleSheet.create(
  { 
    largeText: {
      fontSize: WIDE_DEVICE ? 44 : 36,
      fontWeight: fontWeight,
      color: color
    },
    title1: {
      fontSize: WIDE_DEVICE ? 36 : 27,
      fontWeight: fontWeight,
      color: color
    },
    title2: {
      fontSize: WIDE_DEVICE ? 30 : 22,
      fontWeight: fontWeight,
      color: color
    },
    title3: {
      fontSize: WIDE_DEVICE ? 27 : 19,
      fontWeight: fontWeight,
      color: color
    },
    headline: {
      fontSize: WIDE_DEVICE ? 26 : 16,
      fontWeight: "500",
      color: Colors.dark.alpha1
    },
    body: {
      fontSize: WIDE_DEVICE ? 20 : 16,
      fontWeight: fontWeight,
      color: color
    },
    callout: {
      fontSize: WIDE_DEVICE ? 18 : 15,
      fontWeight: fontWeight,
      color: color
    },
    subhead: {
      fontSize: WIDE_DEVICE ? 17 : 14,
      fontWeight: fontWeight,
      color: color
    },
    footnote: {
      fontSize: WIDE_DEVICE ? 16 : 13,
      fontWeight: fontWeight,
      color: color
    },
    caption1: {
      fontSize: WIDE_DEVICE ? 14 : 12,
      fontWeight: fontWeight,
      color: color
    },
    caption2: {
      fontSize: WIDE_DEVICE ? 12 : 11,
      fontWeight: fontWeight,
      color: color
    }
});

  