import React from 'react';
import {View, Text, StyleSheet, Animated, ActivityIndicator, Image, TouchableOpacity } from 'react-native';
import { Feather } from '@expo/vector-icons';
import API from '../utils/API.js';
import {  InputToolbar, Send } from 'react-native-gifted-chat'
import Colors from '../utils/Colors.js';
import FontStyles from '../utils/FontStyles';

export default class ActiveUser extends React.Component {
  constructor(props){
    super(props);
    
    this.state = {
      activeUser: 0
    }
  }

  componentDidMount() {
    this.props.socket.on('online_'+this.props.roomId, this.onReceiveCounter)
  }

  onReceiveCounter = (data) => {
    this.setState({activeUser: data})
  }

  render() {
    return (
      <View style={styles.container}>
        <Text style={[FontStyles.footnote, {color: Colors.white.alpha1, fontWeight: "700"}]}>
          {this.state.activeUser + " izleyici"}
        </Text>
      </View>
    );
  }   
}

const styles =  StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "baseline",
    position: "absolute",
    top: 20,
    left: 20,
    paddingHorizontal: 15,
    marginVertical: 7.5,
    paddingVertical: 5,
    borderRadius: 20,
    backgroundColor: Colors.red.alpha1,
  },
});



