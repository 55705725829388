import React from 'react';
import { TouchableOpacity, Animated } from 'react-native';

export default class ScaleButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.animatedValue = new Animated.Value(1);

    this.handlePressIn = this.handlePressIn.bind(this);
    this.handlePressOut = this.handlePressOut.bind(this);
  }

  handlePressIn = () => {
    Animated.spring(this.animatedValue, {
      toValue: .95,
      useNativeDriver: true
    }).start()
  }

  handlePressOut = () => {
    Animated.spring(this.animatedValue, {
      toValue: 1,
      useNativeDriver: true
    }).start()
  }

  render() {
    return (
      <Animated.View style={[{transform: [{ scale: this.animatedValue}], alignSelf: "baseline"}, this.props.containerStyle]}>
        <TouchableOpacity 
          onPressIn={this.handlePressIn}
          onPressOut={this.handlePressOut}
          disabled={this.props.disabled}
          activeOpacity={this.props.activeOpacity || 1}
          delayLongPress={this.props.delayLongPress}
          onLongPress={this.props.onLongPress}
          onTouchEnd={this.props.onTouchEnd}
          onPress={this.props.onPress}
          style={this.props.style}
        >
          {this.props.children}
        </TouchableOpacity>
      </Animated.View>
    )
  }
}