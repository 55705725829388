import React from 'react';
import {View, Text, StyleSheet, Animated, ActivityIndicator, Image, TouchableOpacity } from 'react-native';
import { Feather } from '@expo/vector-icons';
import API from '../utils/API.js';
import Colors from '../utils/Colors.js';
import FontStyles from '../utils/FontStyles';
import ActiveUser from './ActiveUser';
import GiftedChatComponent from './GiftedChatComponent';
import { LinearGradient } from 'expo-linear-gradient';
import ReactPlayer from 'react-player'
import {
  isMobile,
  isSafari,
  browserVersion
} from "react-device-detect";

export default class LiveStream extends React.PureComponent {
  constructor(props){
    super(props);
    
    this.socket = this.props.navigation.getParam("socket")
    this.chatValue = new Animated.Value(0)
    this.chatToggle = false
 
    this.xAnim = {
      transform: [
        {
          translateX: this.chatValue.interpolate({
            inputRange: [0, 1],
            outputRange: [isMobile ? -400 : -50, 0],
          })
        }
      ]
    }

    this.state = {
      loadingVideo: true,
      liveUrl: null,
    }
  }

  componentDidMount() {
    this.getLive()
  }

  componentWillUnmount() {
    this.socket.emit('stop', this.state.room_id)
  }

  getLive = () => {
    API.getLive(this.props.navigation.getParam("eventId")).then((response) => {
      let url = response.data.live.url
      this.setState({liveUrl: url, room_id: response.data.live.room_id}, () => {
        this.init(response.data.live.room_id)
      })
    }).catch((e) => {
      this.setState({loadingVideo:false})
    })
  }

  //SOCKET FUNCS
  init = (roomId) =>  {
    this.socket.emit('start', roomId);
    if(!isMobile){
      this.animateChat()
    }
  }

  animateChat = () => {
    Animated.spring(this.chatValue, {
      toValue: this.chatToggle ? 0 : 1,
      useNativeDriver: true,
      friction: 6
    }).start()
    this.chatToggle = !this.chatToggle
  }

  render() {
    return (
      <View style={styles.container}>
        {
          this.state.liveUrl &&
          <View>
            <ReactPlayer 
              url={this.state.liveUrl}
              //onReady={() => this.setState({loadingVideo: false})}
              playing={true}
              controls={isSafari ? browserVersion < 15 ? true : false : false}
              width="100%"
              height={isSafari ? browserVersion < 14 ? isMobile ? 200 : "auto" : "auto" : "auto"}
            />
          </View>
          
        }

        {
          !this.state.liveUrl &&
          <View style={{position: "absolute", justifyContent: "center", alignItems:"center", top:0, bottom:0, right: 0, left: 0 }}>
            <ActivityIndicator size={"large"} color={"white"}/>
            <Text style={[FontStyles.title2, { color: Colors.white.alpha1}]}>
              Yükleniyor...
            </Text>
          </View>
        }

        
        <Animated.View style={[this.xAnim, {opacity: this.chatValue, position: "absolute", paddingLeft: 20, paddingBottom: 20, height: "100%", width: isMobile ? "60%" : "33%", minWidth: 150, bottom: !isMobile ? isSafari ? browserVersion < 14 ? 70 : 0 : 0 : 0, left: 0, }]}>
          <LinearGradient start={[0,1]} end={[0,1]} style={styles.graLeft} colors={["black", "transparent"]}/>
          {
            this.state.room_id &&
            <GiftedChatComponent
              socket={this.socket}
              roomId={this.state.room_id}
            />
          }
        </Animated.View>

        <View style={styles.headerContainer}>
          <TouchableOpacity style={styles.logout} onPress={() => this.animateChat()}>
            <Feather name={"message-square"} size={isMobile ? 20 :30} color={Colors.white.alpha1}/>
          </TouchableOpacity>
          <TouchableOpacity style={styles.logout} onPress={() => {this.props.navigation.state.params.reloadHome(); this.props.navigation.goBack()}}>
            <Feather name={"x"} size={isMobile ? 20 : 30} color={Colors.white.alpha1}/>
          </TouchableOpacity>
        </View>

        {
          this.state.room_id &&
          <ActiveUser 
            socket={this.socket}
            roomId={this.state.room_id}
          />
        }
      </View>
    );
  }   
}

const styles =  StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.black.alpha1,
  },
  logout: {
    height: isMobile ? 44 : 60,
    width: isMobile ? 44 : 60,
    marginRight: 20,
    justifyContent: 'center',
    alignItems: "center",
    borderRadius: 100,
    backgroundColor: Colors.dark.alpha05
  },
  textInput: {
    width: "100%",
    paddingVertical: 17,
    backgroundColor: Colors.white.alpha03,
    borderRadius: 5, 
    textAlign: "center",
    marginTop: 25,
    color: Colors.white.alpha1,
    fontWeight: "700"
  },

  buttonSend: {
    width: "100%",
    paddingVertical: 17,
    borderRadius: 5, 
    backgroundColor: Colors.red.alpha1,
    textAlign: "center",
    justifyContent: 'center',
    alignItems: "center",
    marginTop: 15,
  },
  graLeft: {
    position: "absolute",
    left: 0,
    bottom: 0,
    top: 0,
    right: 0,
  },


  headerContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    flexDirection: "row",
    paddingVertical: 20,
    paddingHorizontal: 15,
    alignItems: 'center',
  },
  
  messageSquare: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 44,
    marginRight: 10,
    height: 44,
    backgroundColor: Colors.darkColor5,
    borderRadius: 10,
  },
  messageUnavailableLine: {
    position: "absolute",
    width: 30,
    height: 2.5, 
    backgroundColor: Colors.red2,
    borderRadius: 15,
    transform: [{rotate: "45deg"}]
  },
  messsageContainer: {
    paddingVertical: 0,
    flexDirection: "row",
    marginHorizontal: 10,
    height: 50,
  },  
  inputToolBarStyle: {
    backgroundColor: "transparent",
    borderTopWidth: 0,
    marginHorizontal: 10,
  },
  userImg: {
    width: 34,
    height: 34,
    borderRadius: 17,
    backgroundColor: Colors.grayLight.alpha05
  },
  commentInput: {
    backgroundColor: "transparent",
    flex: 1,
    borderWidth: 2,
    borderColor: "rgba(255,255,255,0.66)",
    borderRadius: 30,
    paddingLeft: 20,
    height: 44,
    color: Colors.white.alpha1,
    fontSize: 15,
    fontWeight: "500",
  },
});



