import React, { PureComponent } from 'react';
import { Text, StyleSheet,TextInput, ActivityIndicator, Image, View } from 'react-native';
import Colors from '../../utils/Colors';
import FontStyles from '../../utils/FontStyles';
import ScaleButton from '../../components/ScaleButton';
import API from "../../utils/API"
import {  isMobile } from "react-device-detect";

export default class Register1 extends PureComponent {
  constructor(props){
    super(props)

    this.code = ""

    this.state = {
      loading: false,
      text: "+" + this.props.navigation.getParam("phone") + " numaralı telefonunuza 6 haneli bir kod gönderdik.",
    }
  }

  send = () => {
    this.setState({loading: true}, () => {
      API.submitAuthCode(this.props.navigation.getParam("phone"), this.code).then((response) => {
        if(response.success && response.data){
          API.setCacheID(response.data.hash).then(() => {
            API.setUserToStorage(response.data.user).then(() => {
              this.props.navigation.navigate("MainStack") 
            }).catch((e)=>{
            })
          }).catch((e)=>{
          })
        }else{
          this.setState({loading: false, text: response.message})
        }
      }).catch((e) => {
        this.setState({loading: false, text: "Bir şeyler ters gitti. İnternet bağlantınızı kontrol ediniz."})
      })
    })
  }

  render() {
    return (
      <View style={styles.container}>
        <Image style={styles.logo} resizeMode="contain" source={require("../../assets/images/logo.png")}/>
        <Text style={[FontStyles.title1, {fontWeight: "700", color: Colors.white.alpha09}]}>
          {"Kodu Giriniz"}
        </Text>
        <Text style={[FontStyles.body, {fontWeight: "400", width: isMobile ? "90%" : "50%", textAlign: "center", color: Colors.white.alpha05, marginTop: 20, marginBottom: 10}]}>
          {this.state.text}
        </Text>
        <View style={styles.textInputContainer}>
          <TextInput
            placeholder={"SMS Kodu"}
            placeholderTextColor={Colors.white.alpha05}
            underlineColorAndroid={"transparent"}
            maxLength={6}
            autoFocus
            style={[FontStyles.callout, styles.input, {fontWeight: "700"}]}
            keyboardType={"number-pad"}
            autoCapitalize={"none"}
            autoCompleteType={"off"}
            autoCorrect={false}
            onSubmitEditing={this.send}
            onChangeText={text => this.code = text}
          />
        </View>
        
        <ScaleButton disabled={this.state.loading} onPress={this.send} style={styles.continueButton} containerStyle={styles.continueButtonContainer}>
          {
            this.state.loading ? 
            <ActivityIndicator color={Colors.white.alpha1}/>
            :
            <Text style={[FontStyles.body, {fontWeight: "500", color: Colors.white.alpha1}]}>
              Kodu Gönder
            </Text>
          }
        </ScaleButton>
      </View>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 30,
    alignItems: "center",
    justifyContent: 'center',
    backgroundColor: Colors.black.alpha1,
  },
  countryButtons: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingVertical: 15,
    borderBottomColor: Colors.grayLight.alpha1,
    borderBottomWidth: 2,
  },
  modalStyle: {
    backgroundColor: Colors.dark.alpha07,
    width: "100%",
    height: "100%",
    justifyContent: 'center',
    alignItems: "center"
  },
  modelInnerContaner: {
    width: "50%",
    height: "50%",
    backgroundColor: Colors.white.alpha1,
    borderRadius: 10,
    paddingHorizontal: 20,

  },
  logo: {
    width: "33%",
    height: "25%",
  },
  textInputContainer: {
    width: isMobile ? "90%" : "50%",
    marginTop: 20,
    alignSelf: "center",
    height: 60,
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: "center",
  }, 
  countryCodeButton: {
    paddingLeft: 15,
    paddingRight: 20,
    marginRight: 16,
    height: 60,
    flex: 1,
    minWidth: 80,
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: "center",
    borderRadius: 5,
    borderWidth: 2,
    borderColor: Colors.grayLight.alpha1
  },
  input: {
    backgroundColor: Colors.white.alpha02,
    height: 60,
    color: Colors.white.alpha1,
    flex: 1,
    textAlign: "center",
    borderRadius: 5,
    paddingHorizontal: 30,
    justifyContent: "center"
  },
  continueButton: {
    height: 60,
    width: "100%",
    justifyContent: 'center',
    alignItems: "center",
  },
  continueButtonContainer: {
    justifyContent: "center",
    backgroundColor: Colors.red.alpha1,
    height: 60,
    alignSelf: "center",
    marginTop: 25,
    minWidth: 300,
    borderRadius: 5,
    width: isMobile ? "90%" : "50%",
  },
})