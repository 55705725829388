


import React from 'react';
import {View, Text, StyleSheet, TouchableOpacity, TextInput, Animated,Dimensions, Image } from 'react-native';
import Colors from '../utils/Colors.js';
import { GiftedChat, InputToolbar, Send } from 'react-native-gifted-chat'
import FontStyles from '../utils/FontStyles.js';
import {
  isMobile,
  isSafari,
  browserVersion
} from "react-device-detect";

const SCREEN_WIDTH = Dimensions.get("screen").width
const SCREEN_HEIGHT = Dimensions.get("window").height

export default class GiftedChatComponent extends React.PureComponent {
  constructor(props){
    super(props);
    
    this.chatYValue = new Animated.Value(0)
    this.state = {
      messages: [],
      mes: "",
      user: {
        //curren user info verecen. message eklerken dikkat et ekstra bişi yapmaya bilirsin
        _id: 1,
        name: 'Emre',
        avatar: 'https://placeimg.com/140/130/any',
      }, 
    }
  }

  componentDidMount() {
    this.props.socket.on('receive', this.onReceivedMessage)
  }

  onReceivedMessage = (mes) => {
    let obj = {}
    obj["text"] = mes.message
    obj["_id"] = (mes.user_id + Math.random()*10000)
    obj["user"] = {
      avatar: mes.avatar,
      name: mes.username,
      id: mes.user_id
    }
    this.setState((previousState) => ({ messages: GiftedChat.append(previousState.messages, [obj]) }));
  }
  
  onSend = () => {
    let ob = {}
    if(this.state.mes.length > 0){
      ob["text"] = this.state.mes
      this.props.socket.emit('send', ob)
      this.setState({mes: ""}, () => {
        this.input.focus()
      })
    }
  }

  // //CHAT FUNCS
  renderInputToolbar = (props) => {
    return (
      <View  style={styles.inputToolBarStyle}>
        <TextInput
          multiline={false}
          style={styles.commentInput}
          selectionColor={Colors.white.alpha04}
          placeholderTextColor={"rgba(255,255,255,0.5)"}
          autoCorrect={false}
          ref={ref => this.input = ref}
          placeholder={"Yorum Yap"}
          autoCompleteType={"off"}
          value={this.state.mes}
          onChangeText={text => this.setState({mes: text})}
          onSubmitEditing={this.onSend}
        />
        <TouchableOpacity style={styles.sendButton} onPress={this.onSend} activeOpacity={0.9}>
          <Text style={[FontStyles.subhead, {color: Colors.white.alpha1, fontWeight: "800"}]}>
            Gönder
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  renderMessage = (m) => {
    let x = this.state.messages.indexOf(m.currentMessage) * 50
    let margin =  SCREEN_HEIGHT * 0.4
    let opacity = this.chatYValue.interpolate({
      inputRange: [margin - x, margin + (50*4) - x],
      outputRange:[1, 0],
    });

    return ( 
      <Animated.View style={[styles.messsageContainer, {opacity: opacity}]}>
        <Image style={styles.userImg} resizeMode={"cover"} source={{uri: m.currentMessage.user.avatar}}/>
        <View style={{marginLeft: 7.5, width: isMobile ? "100%" : "80%",}}>
          <Text style={{fontSize: 14, fontWeight: "600", color: Colors.white.alpha1}}>
            {m.currentMessage.user.name}
          </Text>
          <Text style={{fontSize: 14, fontWeight: "500", color: Colors.white.alpha08}}>
            {m.currentMessage.text}
          </Text>
        </View>
      </Animated.View>
    )
  }

  onScroll = (event) => {
    this.chatYValue.setValue(-event.nativeEvent.contentOffset.y);    
  }

  render() {
    return (
      <GiftedChat
        renderUsernameOnMessage={false}
        showUserAvatar={false}
        renderInputToolbar={this.renderInputToolbar}        
        maxInputLength={120}
        renderMessage={this.renderMessage}
        listViewProps={
          {
            showsVerticalScrollIndicator: false,
            removeClippedSubviews: false,
            onScroll: this.onScroll, 
            scrollEventThrottle: 4,
            contentContainerStyle: {paddingBottom: 50}
          }
        }
        messages={this.state.messages}
        user={this.state.user}
      />
    );
  }   
}

const styles =  StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "baseline",
    paddingHorizontal: 10,
    marginVertical: 5,
    paddingVertical: 5,
    borderRadius: 20,
    marginLeft: 15,
    marginBottom: 20,
    backgroundColor: Colors.red.alpha1,
  },


  headerContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    flexDirection: "row",
    paddingVertical: 20,
    paddingHorizontal: 15,
    alignItems: 'center',
  },
  
  messageSquare: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 44,
    marginRight: 10,
    height: 44,
    backgroundColor: Colors.darkColor5,
    borderRadius: 10,
  },
  messageUnavailableLine: {
    position: "absolute",
    width: 30,
    height: 2.5, 
    backgroundColor: Colors.red2,
    borderRadius: 15,
    transform: [{rotate: "45deg"}]
  },
  messsageContainer: {
    paddingVertical: 0,
    flexDirection: "row",
    marginHorizontal: 10,
    marginBottom: 15,
  },  
  inputToolBarStyle: {
    backgroundColor: "transparent",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 10,
  },
  sendButton: {
    paddingHorizontal: 15, 
    paddingVertical: 5,
    justifyContent: 'center',
    alignItems: "center"
  },
  userImg: {
    width: 34,
    height: 34,
    borderRadius: 17,
    backgroundColor: Colors.grayLight.alpha05
  },
  commentInput: {
    backgroundColor: "transparent",
    flex: 1,
    borderWidth: 2,
    borderColor: "rgba(255,255,255,0.66)",
    borderRadius: 30,
    paddingLeft: 20,
    height: 44,
    paddingHorizontal: 20,
    color: Colors.white.alpha1,
    fontSize: 15,
    fontWeight: "500",
  },
});