import React from 'react';
import { createAppContainer, createSwitchNavigator } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import API from "./utils/API"
import { View, Image } from "react-native"

export default class App extends React.PureComponent {
  constructor(props){
    super(props);

    this.state = {
      assetsReady: false,
      userReady: false,
      isUserLogin: false
    }
  }

  componentDidMount() {
    this.checkUser()
  }

  checkUser = () => {
    API.initUser().then((response) => {
      if(response && response.data && response.data.info){
        this.setState({isUserLogin:true, userReady: true})
      }else{
        this.setState({isUserLogin: false, userReady: true})
      }
    }).catch(() => {
      this.setState({isUserLogin: false, userReady: true})
    })
  }
  
  render() {
    const MainStack = createStackNavigator(
      { 
        MainScreen: MainScreen,
        LiveStream: LiveStream
      },
      {
        initialRouteName: "MainScreen",
        mode: "card",    
        headerMode: "none",
      }
    );

    const RegisterStack = createStackNavigator(
      {  
        Register1: Register1,
        Register2: Register2,
        
      },
      {
        initialRouteName: "Register1",
        headerMode: "none",
      }
    );

    const SwitchStack = createSwitchNavigator(
      {
        MainStack: MainStack,
        RegisterStack: RegisterStack,
      },
      {
        initialRouteName: this.state.isUserLogin ? "MainStack" : "RegisterStack",
        headerMode: 'none',
      }
    );
    
    const AppContainer = createAppContainer(SwitchStack);

    if (!this.state.userReady){
      return( 
        <View style={{justifyContent: "center", flex: 1, alignItems: "center"}}>
          <Image source={require("./assets/banner.png")} resizeMode={"contain"} style={{width: "50%", height: "50%"}}/>
        </View>
      );
    }

    return ( 
      <AppContainer/>
    )
    
  }
}

import MainScreen from "./Screens/MainScreen"
import Register1 from "./Screens/Register/Register1"
import Register2 from "./Screens/Register/Register2"
import LiveStream from './Screens/LiveStream';







