import React from 'react';
import { Text, StyleSheet,TextInput,Animated, FlatList, ActivityIndicator, ScrollView, TouchableOpacity, Dimensions, Image, View, Alert } from 'react-native';
import Colors from '../../utils/Colors';
import FontStyles from '../../utils/FontStyles';
import ScaleButton from '../../components/ScaleButton';
import CountryCodes from "./CountryCodes.json"
import { Feather } from '@expo/vector-icons';
const SCREEN_WIDTH = Dimensions.get("window").width
const SCREEN_HEIGHT = Dimensions.get("window").height
import API from "../../utils/API"
import {  isMobile } from "react-device-detect";
export default class Register1 extends React.PureComponent {
  constructor(props){
    super(props)

    this.phone = ""

    this.state = {
      loading: false,
      modalVisible: false,
      phoneSelectionFocus: true,
      text: "Giriş yapmak için lütfen telefon numaranızı giriniz.",
      sendButtonFocus: false,
      focusItemName: "Türkiye",
      selectedCountryCode: {
          "name": "Türkiye",
          "value": "+90",
          "code": "TR"
      },
    }
  }

  send = () => {
    if(this.phone.length < 8){
      this.setState({text: "Lütfen geçerli bir telefon numarası giriniz."})
      return null
    }
    this.setState({loading: true}, () => {
      let cc = this.state.selectedCountryCode.value.substr(1, this.state.selectedCountryCode.length)
      API.submitPhoneNumber(cc + this.phone).then((response) => {
        if(response.success){
          this.props.navigation.navigate("Register2", {phone: cc + this.phone})
        }else{
          this.setState({loading: false, text: response.message})
        }
      }).catch((e) => {
        this.setState({loading: false, text: "İnternet bağlantınızı kontrol ediniz."})
      })
    })  
  }

  renderItem = ({item}) => {
    return (
      <TouchableOpacity 
        activeOpacity={0.3} 
        onPress={() => this.setState({selectedCountryCode: item, modalVisible: false})}
        style={[styles.countryButtons, {backgroundColor: this.state.focusItemName == item.name ? Colors.red.alpha01 : "transparent"}]}
        onFocus={() => this.setState({focusItemName: item.name})}
      >
        <Text style={[FontStyles.callout, {fontWeight: "500", marginLeft: 5, color: Colors.dark.alpha07}]}>
          {item.name}
        </Text>
        <Text style={[FontStyles.callout, {fontWeight: "700", marginLeft: 5, color: Colors.dark.alpha1}]}>
          {item.value}
        </Text>
      </TouchableOpacity>
    )
  }

  numberCodeModal = () => {
    return (
      <View style={styles.modalStyle}>
        <View style={styles.modelInnerContaner}>
          <FlatList
            data={CountryCodes}
            removeClippedSubviews
            initialNumToRender={15}
            style={{flex: 1, width: "100%"}}
            onEndReachedThreshold={0.1}
            keyExtractor={item => item.value + item.name}
            renderItem={this.renderItem}
          />
        </View>
      </View>
    )
  }

  render() {
    return (
      <View style={styles.container}>
        <Image style={styles.logo} resizeMode="contain" source={require("../../assets/images/logo.png")}/>
        <Text style={[FontStyles.title1, {fontWeight: "700", color: Colors.white.alpha09}]}>
          {"Hoş geldiniz!"}
        </Text>
        <Text style={[FontStyles.body, {fontWeight: "400", width: "50%", textAlign: "center", color: Colors.white.alpha05, marginTop: 20, marginBottom: 10}]}>
          {this.state.text}
        </Text>
        <View style={styles.textInputContainer}>
          <TouchableOpacity 
            style={[styles.countryCodeButton, {borderColor: Colors.white.alpha03}]} 
            activeOpacity={0.5} 
            onPress={() => this.setState({modalVisible: true})}
          >
            <Feather name={"chevron-down"} size={26} color={Colors.white.alpha05}/>
            <Text style={[FontStyles.callout, {fontWeight: "500", marginLeft: 5, color: Colors.white.alpha07}]}>
              {this.state.selectedCountryCode.value}
            </Text>
          </TouchableOpacity>
          <TextInput
            placeholder={"Telefon Numarası"}
            placeholderTextColor={Colors.white.alpha05}
            underlineColorAndroid={"transparent"}
            style={[FontStyles.callout, styles.input, {fontWeight: "700"}]}
            keyboardType={"number-pad"}
            maxLength={10}
            autoCapitalize={"none"}
            isTVSelectable={true}
            ref={ref => this.input = ref}
            autoFocus
            autoCompleteType={"off"}
            autoCorrect={false}
            onSubmitEditing={this.send}
            onChangeText={text => this.phone = text}
          />
        </View>
        
        <TouchableOpacity 
          disabled={this.state.loading} 
          onPress={this.send} 
          style={[styles.continueButton, { backgroundColor: Colors.red.alpha1}]}
        >
          {
            this.state.loading ? 
            <ActivityIndicator color={Colors.white.alpha1}/>
            :
            <Text style={[FontStyles.body, {fontWeight: "500", color: Colors.white.alpha1}]}>
              Kodu Gönder
            </Text>
          }
          
        </TouchableOpacity>
        {
          this.state.modalVisible &&
          <Animated.View style={[styles.modalContainer, {opacity:1}]}>
            {this.numberCodeModal()}
          </Animated.View>
        }
        
      </View>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 30,
    alignItems: "center",
    justifyContent: 'center',
    backgroundColor: Colors.black.alpha1,
  },
  modalContainer: {
    zIndex: 1200,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  countryButtons: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingVertical: 15,
    borderBottomColor: Colors.grayLight.alpha1,
    borderBottomWidth: 2,
  },
  modalStyle: {
    backgroundColor: Colors.black.alpha07,
    width: "100%",
    height: "100%",
    justifyContent: 'center',
    alignItems: "center"
  },
  modelInnerContaner: {
    width: "50%",
    height: "50%",
    backgroundColor: Colors.white.alpha1,
    borderRadius: 10,
    paddingHorizontal: 20,

  },
  logo: {
    width: "33%",
    height: "25%",
  },
  textInputContainer: {
    width: isMobile ? "100%" : "50%",
    marginTop: 20,
    alignSelf: "center",
    height: 60,
    paddingHorizontal: isMobile ? 20 : 0,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: 'center',
  },  
  countryCodeButton: {
    paddingLeft: 15,
    paddingRight: 20,
    marginRight: 16,
    height: 60,
    width: 80,
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: "center",
    borderRadius: 7,
    borderWidth: 2,
    borderColor: Colors.grayLight.alpha1
  },
  input: {
    backgroundColor: Colors.white.alpha02,
    height: 60,
    color: Colors.white.alpha1,
    flex: 1,
    textAlign: "center",
    borderRadius: 5,
    justifyContent: "center"
  },
  continueButton: {
    width: "100%",
    maxWidth: isMobile ? SCREEN_WIDTH - 40 : "50%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.red.alpha1,
    height: 60,
    alignSelf: "center",
    marginTop: 25,
    borderRadius: 5,
  },
})