import AsyncStorage from '@react-native-community/async-storage';

const BASE_URL="https://api.sosyofest.com/"
const STORAGE_PATH_PREFIX='milyoncard';
const STORAGE_USER_PATH=STORAGE_PATH_PREFIX+'/currentUser';
const HEADERS={
	'Content-Type': 'application/x-www-form-urlencoded',
}

const API={
	_storage: {
		getItem: AsyncStorage.getItem,
		setItem: AsyncStorage.setItem,
		removeItem: AsyncStorage.removeItem,
	},

	makeHttpRequest: function (relativePath, method, params) {
		return this.getCacheID().then((cacheID) => {
			if (cacheID) {
				HEADERS['Cache-ID']=cacheID
			}
		

			return fetch(
				BASE_URL+relativePath,
				{
					method: method,
					headers: HEADERS,
					body:  params
				}).then((response) => {
					return response.json()
				}, (e) => { return e })
		}, (err) => { return err })
	},

	//USER LOCAL CALLS
	getCacheID: function () { //DONE!
		return this._storage.getItem(STORAGE_USER_PATH+"/cacheID").then((cacheID) => {
			if (cacheID) {
				return JSON.parse(cacheID)
			}
			return null
		}, (err) => {
			return err;
		})
	},
	setCacheID: function (cacheID) { //DONE!
		if (cacheID===null||cacheID===undefined||cacheID==="") {
			return this._storage.removeItem(STORAGE_USER_PATH+"/cacheID").then(() => {
			}, (err) => {
				return err;
			});
		} else {
			return this._storage.setItem(STORAGE_USER_PATH+"/cacheID", JSON.stringify(cacheID)).then(() => {
			}, (err) => {
				return err;
			});
		}
	},
	getUserFromStorage: function () { //DONE!
		return this._storage.getItem(STORAGE_USER_PATH+"/data").then((user) => {
			if (user) {
				return JSON.parse(user)
			}
			return null
		}, (err) => {
			return err;
		})
	},
	setUserToStorage: function (user) { //DONE!
		if (user===null||user===undefined||user==={}) {
			return this._storage.removeItem(STORAGE_USER_PATH+"/data").then(() => {
			}, (err) => {
				return err;
			});
		} else {
			return this._storage.setItem(STORAGE_USER_PATH, JSON.stringify(user))
			.then((val) => {
				return Promise.resolve(val);
			})
		}
	},


	//USER RELATED 
	initUser: function () { //DONE!
		return this.makeHttpRequest('users/init/', 'GET', null)
	},
	updateUser: function (fullname, email) { //DONE!
		return this.makeHttpRequest('users/update/', 'POST', `fullname=${fullname}&email=${email}`)
	},
	sendPushToken: function (token) {//DONE!
		return this.makeHttpRequest("users/set-push/", "POST", {key: token})
	},

	//AUTH ENDPOINTS
	submitPhoneNumber: function (phoneNumber) { //DONE!
		return this.makeHttpRequest('users/register/', 'POST', `mobile=${phoneNumber}`)
	},
	submitAuthCode: function (phoneNumber, code) { //DONE!
		return this.makeHttpRequest('users/register/', 'POST', `mobile=${phoneNumber}&code=${code}`)
	},
	logout: function () { //DONE!
		return this.setCacheID(null).then(() => {
			this.setUserToStorage(null).then(() => {
				return true
			}, (err) => {
				return err;
			})
		}, (err) => {
			return err;
		});
	},


	//MAINS
	getLiveEvents: function () { //DONE!
		return this.makeHttpRequest('events/live-tv/', 'POST', {})
	},
	getEvents: function () { //DONE!
		return this.makeHttpRequest('events/list/', 'GET', null)
	}, 
	getLive: function (id) { //DONE!
		return this.makeHttpRequest('events/get-live/'+id + "/", 'GET', null)
	}, 
	getEvent: function (id) { //DONE!
		return this.makeHttpRequest('events/get/'+ id + "/", 'GET', null)
	}, 
	sendPNR: function (pnr) { //DONE!
		return this.makeHttpRequest(`/tickets/claim/${pnr}/`, 'POST', {})
	},
	// events/get/$id/ (canlı lmayanlar)
	// events/get-live/$id/ (canlılar)
};

export default API